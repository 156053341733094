import type { AppProps } from 'next/app'
import { Provider, teamsTheme } from '@fluentui/react-northstar'
import { SessionProvider } from 'next-auth/react'
import '../styles/globals.css'
import 'primereact/resources/themes/fluent-light/theme.css' //theme
import 'primereact/resources/primereact.min.css' //core css
import 'primeicons/primeicons.css' //icons
import 'styles/customizedPrimeTheme.scss'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { Tracker } from '@/components/Tracker'

const PATHS_WITHOUT_SESSION_PROVIDER = ['/teams_logout', '/teams_callback']

const MyApp = ({ Component, pageProps: { ...pageProps } }: AppProps) => {
  const router = useRouter()

  const content = (
    <Provider theme={teamsTheme}>
      <Head>
        <title>Delayed Send</title>
        <meta name="description" content="Schedule reminders in Microsoft Teams" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="icon" href="/logo.png" />
      </Head>
      <Component {...pageProps} />
      <Tracker />
    </Provider>
  )

  // SessionProvider disabled in Teams popups because of session not being deleted while signing out
  return PATHS_WITHOUT_SESSION_PROVIDER.some((path) => router.asPath.includes(path)) ? (
    content
  ) : (
    <SessionProvider>{content}</SessionProvider>
  )
}

export default MyApp
